@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  height: 6.5rem;
  width: 50rem;
  pointer-events: none !important;
}

.text {
  font-size: 0.75rem;
  color: ui.$contrast-grey-strong;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-start;
  margin-top: 0.4rem;
}

.badgeCDI {
  margin-top: 0.5rem;
  background-color: ui.$main-yellow-full;
  color: ui.$main-navy-full;
}
