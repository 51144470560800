@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.phoneAndEmail {
  @include ui.XSgrey_mediumleft;
  @extend %one-line-text;
}

.exchangeSummaryTextArea {
  margin-top: 1rem;
}

.sendSMS {
  margin-top: 1.5rem;
  width: 100%;
}

.dateAndStatusContainer {
  display: flex;
  margin-top: 1rem;
  gap: 0.5rem;
}

.datePicker {
  width: 17rem;
}

.dropDown {
  width: 17.5rem;
}

.planningEventsList {
  margin-top: 1rem;
}

.planningEventContainer {
  border-bottom: 1px solid ui.$contrast-grey-medium;
  &:nth-child(2) {
    border-top: 1px solid ui.$contrast-grey-medium;
  }
}

.horizontalCard {
  margin-bottom: -0.25rem;
}

.iconButton {
  padding: 0.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  max-width: 2.5rem;
  margin-left: 0.475rem;
}

.exchangeSummaryMemo {
  font-size: 0.75rem;
  margin-left: 1.5rem;
  padding-bottom: 1rem;
  white-space: pre-wrap;
}

.appointmentTimeContainer {
  display: flex;
  margin-top: 1rem;
}

.appointmentTimePickerContainer {
  width: 15rem;
  display: flex;
}

.appointmentTime {
  margin-top: 1.5rem;
}

.appointmentTimeLeft {
  margin-top: 1.5rem;
  margin-left: -2.75rem;
  width: 7.25rem;
}

.appointmentTimeRight {
  margin-top: 1.5rem;
  width: 7.25rem;
}

.syncGoogleCal {
  margin-top: 1.5rem;
  font-size: 5rem;
}
