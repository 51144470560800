@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-block: 1rem;
  height: 100%;
}

.titleContainer {
  @include ui.Titre_XS;
  color: ui.$palette-navy-500;
}

.illustration {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pdfPreview {
  width: 100%;
  height: 100%;
  border: none;
}
