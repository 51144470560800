@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  @include ui.Lnavyleft;
  margin-bottom: 0.5rem;
}
.photo {
  border-radius: 8px;
  height: calc(100vh - 11rem);
  max-width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  height: calc(100vh - 4.653rem);
}

.responsiveContainer {
  width: min(120rem, 100%);
  height: 100%;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;
  > div:nth-child(2) {
    flex-grow: 1;
  }
}

.expandedLeftContainer {
  width: 100%;
}

.buttonsContainer {
  display: flex;
  gap: 1rem;
  margin-top: 0rem;
}

.stepper {
  margin: 1rem 0 1.25rem 0;
}

.stepperContainer {
  width: 100%;
}

.outerContainer {
  display: flex;
  flex: 1 1;
  height: calc(100% - 2.5rem);
}

.rightContainer {
  width: 50%;
  border-radius: 8px;
  background-color: #3f4767;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
