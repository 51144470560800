@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  margin-right: 1rem;
}

.dot {
  height: 2rem;
  width: 2rem;
  margin-right: -0.5rem;
  text-align: center;
  padding-bottom: 2px;
}

.filterButton {
  max-height: 2.5rem;

  svg {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: -0.5rem;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ui.$contrast-grey-light;
  padding-bottom: 0.5rem;
}

.sideBarTitle {
  width: 11.563rem;
  height: 1.25rem;
  white-space: nowrap;
  @include ui.Snavyleft;
}

.resetButton {
  height: 1rem;
  margin-right: -1rem;
  margin-top: -0.5rem;
}

.openPopup {
  background-color: ui.$main-navy-strong;
  border-color: ui.$main-navy-strong;
  @include ui.Swhitefullleft;
}

.openPopupDot {
  background-color: ui.$main-white-full;
  color: ui.$main-navy-strong;
}
.popupContentContainer::-webkit-scrollbar {
  width: 0.375rem;
}
.popupContentContainer::-webkit-scrollbar-track {
  border-radius: 38px;
  background: ui.$main-blue-light;
  margin: 1rem auto;
}

.popupContentContainer::-webkit-scrollbar-thumb {
  background: ui.$main-blue-full;
  border-radius: 38px;
}

.popupContentContainer {
  max-height: 30rem;
  padding: 1rem 1rem 0 1rem;
  border-radius: 4px;
  overflow-y: auto;
  margin-right: 0.562rem;
}
