@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.card {
  align-items: stretch;
}

.header {
  position: relative;
  width: 16rem;
  padding: 1rem;
  margin: -1rem 0 -1rem -1rem;
  background-color: black;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;

  &.navy {
    background-color: ui.$surface-feedback-dark-brand-full;
  }
  &.yellow {
    background-color: ui.$surface-feedback-alt-yellow-full;
  }
  &.greenish {
    background-color: ui.$surface-feedback-alt-greenish-full;
  }
}

.checkbox {
  position: absolute;
  top: 1rem;
  right: 1rem;
  pointer-events: none;
}

.name {
  @include ui.Paragraph;
  color: ui.$text-body-idle;
}

.qualification {
  @include ui.Legend;
  color: ui.$text-body-idle;
}

.address {
  @include ui.Legend;
  color: ui.$palette-alpha-white-60;
}

.content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;

  hr {
    border: 1px none ui.$border-feedback-disable-medium;
    border-bottom-style: solid;
  }
}

.additionalInfo {
  flex: 1 1;
  display: flex;
}

.status {
  @include ui.Subheader;
  color: ui.$text-body-idle;
  flex: 1 1;
}

.scoring {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include ui.Legend;
  color: ui.$text-body-sstxt;
  margin-bottom: 0.75rem;
}

.contract {
  @include ui.Tag;
  color: ui.$text-body-idle;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  gap: 0.5rem;
}

.rightColumn {
  width: 14rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.stats {
  flex: 1 1;
  display: flex;
  align-items: stretch;
}

.stat {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include ui.Legend;
  color: ui.$text-body-idle;
}

.actions {
  margin-block: 0.5rem;
}
