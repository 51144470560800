@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.diplomaCard {
  width: 88%;
  box-shadow: unset !important;
  border-color: ui.$main-white-full !important;
}

.diplomaCardTitle {
  width: 40rem;
  overflow: clip;
}
