@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.testType {
  padding-bottom: 1.25rem;
}

.test {
  padding-top: 1rem;
}

.testCard {
  width: 88%;
  box-shadow: unset !important;
  border-color: ui.$main-white-full !important;
}

.testCardTitle {
  width: 40rem;
  overflow: clip;
}

.emptyIcon {
  align-self: center;
}
.text {
  margin-top: 1rem;
  width: 21.5rem;
}
