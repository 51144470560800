@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.button {
  margin-left: 1rem;
}

.closeBtn {
  display: flex;
  flex-direction: row;
}

.error {
  color: ui.$system-danger;
}
