@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';
.parsedDocumentLabel {
  @include ui.XSnavyleft;
}

.parsedDocumentLabelTitle {
  margin: 0;
}

.parsedDocumentLabelContainer {
  margin: -0.75rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.flux {
  transform: rotate(90deg);
}
