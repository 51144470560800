@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  margin-top: 1rem;
}

.lightGrey {
  color: ui.$contrast-grey-strong;
  margin-bottom: 0.5rem;
}

.icon {
  margin-top: -1.5rem;
}
