@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex: 1 1;
  min-height: 0;
}

.content {
  flex: 1 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  gap: 1.5rem;
}

.titleBar {
  display: flex;
  align-items: center;
  height: 2.5rem;
}

.title {
  @include ui.Titre_XS;
  color: ui.$text-heading-idle;
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  flex: 1 1;
}

.toolBar {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.illustration {
  @include ui.Paragraph;
  color: ui.$text-body-idle;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.candidatesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  gap: 1rem;
}

.candidatesList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
