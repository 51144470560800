@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.textLength {
  @include ui.XSnavyleft;
}

.textAreaWidth {
  width: 100%;
  height: 10rem;
}

.commentTextArea {
  width: 100%;
  height: 7.5rem;
}

.container {
  width: 88%;
  margin-bottom: 2rem;
}

.title {
  @include ui.Lnavyleft;
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
}

.button {
  width: 11.25rem;
}

.errorMessage {
  @include ui.XSerrorleft;
  margin-top: 0.25rem;
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 88%;
  margin-bottom: 1rem;
}

.toggleLabel {
  @include ui.Snavyleft;
}

.separator {
  height: 1px;
  margin-bottom: 0.875rem;
  background-color: ui.$contrast-grey-medium;
}

.cardsContainer {
  width: 88%;
}

.autosuggestContainer {
  margin-bottom: 1.5rem;
  margin-top: -1.5rem;
}
