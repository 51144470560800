@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  border-bottom: 1px solid ui.$contrast-grey-light;
}

.regionTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
}

.zoneContainer {
  margin-top: 0.5rem;
  padding-left: 1rem;
}

.zoneCheckBox {
  margin-bottom: 1rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 5rem);
  row-gap: 1rem;
  column-gap: 1rem;
  margin-bottom: 2rem;
}
