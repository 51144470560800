@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.illustration {
  @include ui.Paragraph;
  color: ui.$text-body-idle;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.missionId {
  @include ui.Paragraph;
  color: ui.$text-heading-link;
}

.missionInfo {
  @include ui.Legend;
  color: ui.$text-body-sstxt;
}
