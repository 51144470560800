@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.textLength {
  @include ui.XSgrey_mediumleft;
}

.warning {
  color: ui.$system-warning;
  font-family: Graphik;
  font-size: 12px;
  line-height: 1.33;
  margin-top: -1rem;
}

.title {
  @include ui.Lnavyleft;
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
}

.subtitle {
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
}

.candidateCard {
  width: 88%;
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.name {
  width: 20rem;
}

.r1Date {
  width: 10rem;
}

.r2Date {
  padding-right: 1rem;
}

.button {
  width: 18.75rem;
  margin-top: 2.5rem;
}

.label {
  margin-right: 16.875rem;
}

.separator {
  height: 1px;
  margin-top: 2.5rem;
  margin-bottom: 0.875rem;
  background-color: ui.$contrast-grey-medium;
}

.tests {
  width: 100%;
  height: 10rem;
}
