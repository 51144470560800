@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.gridContainer {
  display: grid;
  grid-template-columns: 13.5rem repeat(7, 4.7rem);
  grid-auto-rows: 2.5rem;
  gap: 0.5rem 1rem;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
}

.headerCell {
  padding: 0.5rem;
  text-align: center;
  background-color: ui.$palette-navy-500;
  border-radius: 0.25rem;
  @include ui.Legend;
  color: ui.$palette-alpha-white-100;
  height: 2rem;
}

.cornerCell {
  @include ui.Legend;
  color: ui.$text-body-sstxt;
  padding: 0.5rem;
}

.labelCell {
  @include ui.Legend;
  color: ui.$text-body-idle;
  padding: 0.5rem;
}

.dayCell {
  padding: 0.5rem;
  text-align: center;
}
