@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.pdfDocument {
  position: relative;
  height: max-content;
  border-radius: 8px;
}

.thumbnails {
  position: absolute;
  right: 1rem;
  bottom: 0.5rem;
  display: flex;
  flex-direction: column;
}

.pdfPages {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 3rem;
  height: 1.5rem;
  border-radius: 38px;
  @include ui.XSwhitefullcenter;
  background-color: ui.$main-navy-strong;
}

.currentPageThumbnail {
  width: 3rem;
  height: 3rem;
  border-radius: 3.2px;
  border: solid 2px ui.$main-navy-strong;
  padding: 0 0.325rem;
  background-color: #d8d8d8;
}

.otherPageThumbnail {
  width: 3rem;
  height: 3rem;
  padding: 0 0.45rem;
  background-color: #d8d8d8;
}
