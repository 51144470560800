@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.header {
  display: flex;
  flex-direction: row;
}

.title {
  @include ui.Lnavyleft;
  margin-bottom: 0.875rem;
}

.separator {
  @extend %separator;
}

.toggleMenu {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
