@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  @include ui.Titre_XS;
  color: ui.$text-heading-idle;
  margin-bottom: 1.5rem;
}

.additionalInformationTitle {
  @include ui.Titre_XS;
  color: ui.$text-heading-idle;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.separator {
  height: 1px;
  background-color: ui.$border-feedback-disable-medium;
}
