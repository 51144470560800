@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.titleConfirmation {
  @include ui.XLnavyleft;

  margin: 0 1.5rem 1.5rem 1.5rem;
}
.confirmationContainer {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  row-gap: 1.5rem;
  align-items: center;
  align-self: center;
  @include ui.Mnavyleft;
}
.optionsButtonsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  justify-self: center;
  width: 100%;
}
.confirmationIcon {
  max-width: 17.125rem;
  max-height: 10.188rem;
}

.closeButton {
  height: 2.5rem;
}
