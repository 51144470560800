@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ui.$surface-body-warm;
  padding: 1.5rem;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  max-width: 30rem;
  border-radius: 0.5rem;
  padding: 1.5rem;
  gap: 0.5rem;
  background-color: ui.$surface-feedback-dark-brand-full;
}

.title {
  margin-top: 1rem;
  @include ui.Titre_S;
  color: ui.$text-body-idle;
}

.description {
  @include ui.Paragraph;
  color: ui.$text-body-idle;
}

.error {
  @include ui.Paragraph;
  color: ui.$text-feedback-error-medium;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.brandLogo {
  align-self: flex-start;
  margin-block: 1rem;
}
