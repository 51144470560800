@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 35rem;
  height: 4rem;
  border-top: 1px solid ui.$contrast-grey-medium;
  padding-left: 1rem;

  &:hover {
    cursor: pointer;
    background-color: ui.$contrast-grey-lighter;
  }
}

.selected {
  background-color: ui.$contrast-grey-light;
}

.icon {
  height: 3rem;
  width: 8rem;
}

.licenceInformation {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
}

.identifier {
  @include ui.Sblueleft;
  margin-bottom: 0.188rem;
}

.label {
  @include ui.XSgrey_mediumleft;
  max-width: 22.375rem;
}

.checkIcon {
  margin-left: auto;
  height: 1rem;
  width: 1rem;
  margin-right: 1rem;
}

.selectedText {
  @include ui.Snavyleft;
}
