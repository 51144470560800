@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  width: 88%;
  margin-top: 1.5rem;
}

.qualificationName {
  @include ui.Snavyleft;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 1rem;
  padding-right: 0.5rem;
  background-color: ui.$surface-list-idle-defaut;
  border-bottom: 1px solid ui.$contrast-grey-light;
  justify-content: space-between;

  &:first-of-type {
    border-top: 1px solid ui.$contrast-grey-light;
  }
}

.gellule {
  margin-left: 1rem;
}
