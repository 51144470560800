@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.km25 {
  background-color: ui.$main-greenish-full;
}

.km50 {
  background-color: ui.$main-greenish-strong;
}

.km75 {
  background-color: ui.$main-greenish-medium;
}

.km100 {
  background-color: ui.$main-greenish-light;
  color: ui.$main-greenish-full;
  svg path {
    fill: currentColor;
  }
}
