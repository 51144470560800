@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.testCard {
  width: 88%;
  box-shadow: unset !important;
  border-color: ui.$main-white-full !important;
  margin-bottom: 0;
}

.testCardTitle {
  width: 40rem;
  overflow: clip;
}

.testCardSubtitle {
  margin-top: 0;
}

.testCardSubtitle div {
  margin-top: 0.25rem;
}
