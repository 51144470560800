@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
  width: 100%;
  @include ui.Snavyleft;
}

.label {
  max-width: 75%;
}
