@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.icon {
  width: 1.5rem;
  height: 1.5rem;
  color: ui.$main-white-full;
}

.trigger {
  // we need the !important because for some reason, styles from react-component override this component's style
  width: 2.5rem !important;
  min-width: 0 !important;
  border-width: 0 !important;
  height: 2.5rem !important;
  border-radius: 8px !important;
  padding: 0.5rem !important;
}

.activeTrigger {
  background-color: ui.$contrast-grey-light;
  circle {
    fill: ui.$main-navy-full;
  }
}
