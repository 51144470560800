@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  margin-top: 2rem;
}

.segmentedMenuSelectedText {
  @include ui.Mnavyleft;
}

.tab {
  @include ui.Mblueleft;
}

.menuContainer {
  position: relative;
  margin-left: 2rem;
  margin-top: 2rem;
}
