@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.button {
  color: ui.$main-white-medium;
  padding: 0.5rem;
  border-width: 0;
  height: 3rem;
  width: 3rem;
  min-width: 0;
}

.openButton {
  outline: none;
  background-color: ui.$contrast-grey-lighter;
}

.buttonIcon {
  color: ui.$main-white-full;
}
