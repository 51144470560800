@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

.bullet {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
}

.illustration {
  @include ui.Paragraph;
  color: ui.$text-body-idle;
  display: flex;
  gap: 1rem;
  align-self: flex-start;
  align-items: center;
  margin: 1rem 2rem;
}

.tabsContainer {
  flex: 0;
  margin: 0 2rem 1rem;
}

.tabContainer {
  margin-inline: 2rem;
  flex: 1;
  flex-direction: row;
  display: flex;
  overflow-y: auto;
}

.sideBarContainer {
  max-width: 16rem;
}

.candidateName {
  @include ui.Snavyleft;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.candidateContract {
  @include ui.XSgrey_mediumleft;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-top: 0.25rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ui.$contrast-grey-medium;
}
