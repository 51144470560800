@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.baseFormContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 1.5rem 1.5rem 1.5rem;
  column-gap: 1rem;
  row-gap: 1rem;
}

.formIdentityContainer {
  @extend .baseFormContainer;
  grid-template-rows: 1fr repeat(8, minmax(6rem, auto));
  grid-template-areas:
    'title title '
    'gender gender'
    'name firstName'
    'birthName phone'
    'email email'
    'birthDate nationality'
    'birthCity birthDepartment'
    'birthCountry birthCountry'
    'socialSecurityNumber socialSecurityNumber';
}

.formOtherContainer {
  @extend .baseFormContainer;
  grid-template-rows: 1fr repeat(2, minmax(6rem, auto));
  grid-template-areas:
    'title title '
    'acceptedContracts acceptedContracts'
    'agency origin';
}

.agency {
  grid-area: agency;
}

.birthCountry {
  grid-area: birthCountry;
}

.nationality {
  grid-area: nationality;
}

.birthDate {
  grid-area: birthDate;
  > div {
    > div {
      width: 100%;
    }
  }
}

.birthDepartment {
  grid-area: birthDepartment;
}

.birthCity {
  grid-area: birthCity;
}

.acceptedContracts {
  grid-area: acceptedContracts;
}
.title {
  @include ui.XLnavyleft;
  grid-area: title;
  padding-bottom: 0.5rem;
}
