@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
}
.photo {
  height: calc(100vh - 21.625rem);
}

.documentContainer {
  border-radius: 8px;
  border: solid 1px ui.$contrast-grey-medium;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.confirmButton {
  height: 2.5rem;
  padding: 0.5rem;
}
