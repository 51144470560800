@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-block: 1rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.titleContainer {
  @include ui.Titre_XS;
  color: ui.$text-heading-idle;
}

.charCount {
  @include ui.Legend;
  color: ui.$text-body-sstxt;
  display: inline-flex;
  align-items: flex-end;
  margin-bottom: -0.5rem;
}

.text {
  width: 100%;
  height: 23.5rem;
}
