@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: row;
}

.chartItemContainer {
  width: 50%;
}
