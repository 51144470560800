@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.documentItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3rem;
  border-bottom: 1px solid ui.$contrast_grey_light;
}

.documentName {
  @include ui.Sblueleft;
  margin-left: 1rem;
  margin-right: 0.5rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.uploadDate {
  @include ui.XSgrey_mediumleft;
  margin-top: 4px;
}

.title {
  @include ui.Lnavyleft;
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
}

.button {
  margin: 0.2rem;
  border: 1px solid !important;
  padding: 0.75rem !important;
  border-radius: 0.5rem;
}

.triggeredButton {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  margin-inline: 0.1375rem;
  border: 2px solid !important;
  color: ui.$text-chips-selected-idle !important;
  background-color: ui.$surface-chips-selected-idle !important;
  border-color: ui.$border-chips-selected-idle !important;
}

.close {
  margin-right: -0.25rem;
}

.loading {
  animation: rotation 2s infinite linear;
  margin-right: 0.5rem;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
