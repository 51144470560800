@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.trashCan {
  width: 3.5rem;
  height: 3.5rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  align-self: flex-start;
  cursor: pointer;
  flex-shrink: 0;
}

.trashCan:hover {
  background-color: ui.$contrast-grey-medium;
}
