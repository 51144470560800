@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.illustration {
  @include ui.Paragraph;
  color: ui.$text-body-idle;
  display: flex;
  gap: 1rem;
  align-self: flex-start;
  align-items: center;
}

.toolbar {
  @include ui.Paragraph;
  color: ui.$text-body-idle;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.experiences {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.groupLabel {
  @include ui.Titre_XS;
  color: ui.$text-heading-idle;
  background-color: ui.$surface-body-defaut;
  height: 2.5rem;
  text-transform: lowercase;
  padding-inline: 1rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
}
