@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  height: 4.5rem;
  width: 100%;
  border: 1px solid ui.$contrast-grey-strong;
  border-radius: 4px;
  @include ui.Mblueleft;
  cursor: pointer;

  &.disabled {
    cursor: default;
    @include ui.Mgrey_mediumleft;
    opacity: 0.4;
    border: 1px solid ui.$contrast-grey-medium;
  }

  &:not(.disabled) {
    &:hover {
      background-color: ui.$contrast-grey-lighter;
      border: 2px solid ui.$main-blue-full;
      margin-left: -1px;
      margin-right: -1px;
    }
  }
}

.icon {
  margin-right: 1rem;
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected {
  border: 2px solid ui.$main-blue-full;
  background-color: ui.$contrast-grey-light;
  margin-left: -1px;
  margin-right: -1px;
  @include ui.Mnavyleft;
}
