@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  flex: 1 0 40%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ui.$main-white-full;
  padding: 0.625rem 0.5rem 0.625rem 0.9rem;
  border: 1px solid ui.$contrast-grey-medium;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
}

.leftSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  max-width: 60%;
}

.rightSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.text {
  @include ui.Snavyleft;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  margin-right: 0.5rem;
}
