@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.badge {
  width: max-content;
  @include ui.XSwhitefullleft;
  background-color: ui.$main-navy-full;
  height: 1.5rem;
  white-space: nowrap;
  max-width: 100%;
}

.cross {
  width: 1rem;
  height: 1rem;
}

.badgesContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
