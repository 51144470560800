@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.listContainer {
  margin-top: 1.5rem;
}

.criteriaCard {
  border: none;
  padding-left: 0rem;
  padding-right: 0rem;
}

.separator {
  @extend %separator;
}

.categoryListContainer {
  margin-bottom: 2rem;
}
