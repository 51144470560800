@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.form {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.title {
  @include ui.Lnavyleft;
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
}

.withMarginBottom {
  margin-bottom: 1.5rem;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.button {
  width: 12.5rem;
}

.gmmr {
  width: 21rem;
}

.commentText {
  height: 10rem;
}

.separator {
  height: 1px;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  background-color: ui.$contrast-grey-medium;
}

.margin {
  margin-right: 1rem;
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.toggleLabel {
  @include ui.Snavyleft;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  margin-top: 2.5rem;
  gap: 1rem;
  margin-bottom: 2rem;
}

.errorMessage {
  @include ui.Snavyleft;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

.modalTrigger {
  margin-top: -2rem;
}
