@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  @include ui.hide-scrollbar;
}

.title {
  @include ui.Lnavyleft;
}

.criteria {
  @include ui.Snavyleft;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}

.missionSelection {
  @include ui.Legend;
  color: ui.$text-body-idle;
  display: flex;
  gap: 0.5rem;
}

.emptyIcon {
  margin-top: 6rem;
  align-self: center;
}

.multiCriteriaCard {
  border-radius: 0px 0px 0px 0px !important;
  border-bottom-width: 0px !important;

  &:last-of-type {
    border-radius: 0px 0px 4px 4px !important;
    border-bottom-width: 1px !important;
  }
  &:first-of-type {
    border-radius: 4px 4px 0px 0px !important;
  }
}

.criteriaList {
  margin-bottom: 1.5rem;
}

.searchButton {
  width: 20rem;
  align-self: center;
  position: absolute;
  bottom: 2rem;
  z-index: 2;
}

.text {
  width: 21.5rem;
}

.icon {
  width: 2rem;
  height: 2rem;
}

.iconsTopButtons {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
}

.topButton {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.topButtonText {
  @include ui.XSblueleft;
  margin-left: 0.5rem;
}
