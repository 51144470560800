@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';
.filterContainer {
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid ui.$contrast-grey-medium;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
}

.filterLabel {
  @include ui.Snavyleft;
}

.icon {
  min-width: 1.5rem;
}

.iconButton {
  padding: 0.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  max-width: 2.5rem;
}
