@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.maxMobilityContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.warningMessage {
  text-align: left;
  font-family: Graphik;
  font-size: 12px;
  line-height: 1.33;
  color: ui.$system-warning;
  margin-top: 0.25rem;
}

.maxMobilityInput {
  max-width: 10.25rem;
  margin-right: 1rem;

  div {
    overflow: visible;
  }
}
