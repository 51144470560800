@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-block: 1rem;
}

.titleContainer {
  @include ui.Titre_XS;
  color: ui.$palette-navy-500;
}

.cardsContainer {
  width: 100%;
  display: flex;
  gap: 2rem;
}

.categoryContainer {
  padding-inline: 0.5rem;
  flex: 1 0 20rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.categoryHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include ui.Subheader;
}

.categorySelectedCounter {
  @include ui.Legend;
}

.itemTitle {
  white-space: wrap;
}

.noInfo {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  @include ui.Titre_S;
  color: ui.$text-heading-idle;
}
