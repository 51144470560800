@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.formationContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dateContainer {
  display: flex;
  gap: 1rem;
}

.formationTitle {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.parsedDocumentLabelContainer {
  margin: 0 0 -0.75rem 0;
}
.separator {
  @extend %separator;
}

.removeItem {
  align-self: flex-end;
}
