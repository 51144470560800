@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.triggerButton {
  @include ui.Sblueleft;
}

.trigger {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  gap: 0.5rem;
  cursor: pointer;
}

.text {
  margin-bottom: 2rem;
}
