@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  @include ui.Lnavyleft;
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
}

.subTitle {
  @include ui.Mnavyleft;
  margin-left: 0.5rem;
}

.container {
  width: 88%;
}

.separator {
  height: 1px;
  margin-top: 2.5rem;
  margin-bottom: 0.875rem;
  background-color: ui.$contrast-grey-medium;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  margin-top: 2.5rem;
  gap: 1rem;
  margin-bottom: 2rem;
}

.closeBtn {
  display: flex;
  flex-direction: column;
}

.errorMessage {
  @include ui.XSerrorleft;
  margin-top: 0.25rem;
}

.marginContainer {
  margin-top: 1rem;
}

.textArea {
  margin-bottom: 1rem;
  height: 7.5rem;
  text-overflow-ellipsis: true;
}

.choice {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1rem;

  .date {
    width: 10rem;
    margin-right: 1rem;
  }
}

.globalErrorMessage {
  @include ui.Snavyleft;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}
