@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-block: 1rem;
}

.title {
  @include ui.Titre_XS;
  color: ui.$text-heading-idle;
}

.illustration {
  @include ui.Paragraph;
  color: ui.$text-body-idle;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  gap: 0.5rem;
}
