@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  @include ui.Lnavyleft;
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
}

.jobsDescription {
  @include ui.Snavyleft;
  margin-bottom: 1.5rem;
}

.icon {
  width: 1rem;
  height: 1rem;
}

.dragDropPlaceholder {
  height: 4.625rem;
  border-radius: 8px;
  background-color: rgba(204, 211, 225, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dragDropPlaceholderText {
  @include ui.Mgrey_mediumcenter;
}
