@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.textLength {
  @include ui.XSgrey_mediumleft;
}

.textAreaWidth {
  width: 100%;
  height: 10rem;
}

.container {
  width: 88%;
  height: auto;
  margin-bottom: 2rem;
}

.titleContainer {
  white-space: pre;
}

.title {
  @include ui.Lnavyleft;
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
}

.button {
  width: 17.75rem;
  margin-top: 2.5rem;
}

.errorMessage {
  @include ui.XSerrorleft;
  margin-top: 0.25rem;
}

.needs {
  margin-top: 1rem;
}

.textInput {
  width: 88%;
}

.criteriaCard {
  border-right: none;
  border-left: none;
  background: none;
  border-radius: 0%;
  margin-top: -1px;
  width: 88%;
}

.dragIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.75rem;
}

.experienceCard {
  width: 88%;
  box-shadow: unset !important;
  border-color: ui.$contrast-grey-medium !important;
}

.separator {
  @extend %separator;
  margin-bottom: 0.5rem;
  margin-top: 2.5rem;
  width: 88%;
}

.noExperience {
  @include ui.Snavyleft;
}
