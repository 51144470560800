@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  margin-right: 1rem;
}

.dot {
  height: 2rem;
  width: 2rem;
  margin-right: -0.5rem;
  text-align: center;
  padding-bottom: 2px;
}

.filterButton {
  max-height: 2.5rem;

  svg {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: -0.5rem;
  }
}

.openPopup {
  background-color: ui.$main-navy-strong;
  border-color: ui.$main-navy-strong;
  @include ui.Swhitefullleft;
}

.openPopupDot {
  background-color: ui.$main-white-full;
  color: ui.$main-navy-strong;
}

.popupContentContainer {
  max-height: 36rem;
  padding: 1rem;
  padding-bottom: 0;
  overflow-y: auto;
}

.checkboxContainer {
  margin-left: -0.5rem;
  margin-bottom: 1rem;
  label {
    cursor: pointer;
  }
}

.mainStatusesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.secondaryStatusesContainer {
  margin-top: 1rem;
}
