@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.formContainer {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.formContainer::-webkit-scrollbar {
  display: none;
}

.baseFormContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 1.5rem 1.5rem 1.5rem;
  column-gap: 1rem;
  row-gap: 1rem;
}

.formAddressContainer {
  @extend .baseFormContainer;
  grid-template-rows: 1fr auto repeat(4, minmax(6rem, auto));
  grid-template-areas:
    'title title '
    '. .'
    'apartmentNumber buildingName'
    'road road'
    'locality locality'
    'city city';
}

.stepper {
  margin: 1rem 0 1.25rem 0;
}

.flux {
  transform: rotate(90deg);
}

.formExperiencesContainer {
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem 1.5rem 1.5rem;
  gap: 2rem;
}

.title {
  @include ui.XLnavyleft;
  grid-area: title;
  padding-bottom: 0.5rem;
}

.experiencesTitle {
  @extend .title;
  padding-bottom: 0;
  margin-bottom: -0.5rem;
}

.separator {
  @extend %separator;
  margin-right: 1rem;
}

.formFooter {
  flex-shrink: 0;
}

.formButtons {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin: 0.875rem 1.5rem 0rem 1.5rem;
}

.nextButton {
  margin-left: auto;
}
.detectedAddress {
  margin-bottom: -1rem;
}
.detectedAddressTitle {
  margin-bottom: 0;
}

.city {
  grid-area: city;
}
