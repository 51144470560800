@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.titleConflict {
  @include ui.XLnavyleft;

  margin: 0 1.5rem;
}
.conflictsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  row-gap: 1.125rem;
  align-items: center;
  align-self: center;
  @include ui.Mnavyleft;
}
.optionsButtonsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  justify-self: center;
}
.conflictIcon {
  max-width: 17.125rem;
  max-height: 10.188rem;
}

.table {
  td {
    padding: 0.5rem 0.5rem;
  }
  p {
    overflow-wrap: anywhere;
  }
}

.checkBox {
  display: flex;
  align-items: center;
}
