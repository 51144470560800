@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.listContainer {
  margin-top: 1.5rem;
}

.criteriaCard {
  border: none;
  padding-left: 0rem;
  padding-right: 0rem;
}

.separator {
  @extend %separator;
}

.electricHabilitationButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.375rem;
  margin-bottom: 2.375rem;
  cursor: pointer;
}

.buttonText {
  @include ui.Sblueleft;
  margin-left: 0.5rem;
}

.plusIcon {
  width: 1rem;
  height: 1rem;
}
