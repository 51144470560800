@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  background-color: white;
  padding: 1.5rem;
}

.tableFontStyle {
  font-family: Graphik !important;
  font-size: 16px !important;
  line-height: 1.25 !important;
}

.header {
  text-align: center !important;
  color: ui.$contrast-grey-strong !important;
}

.body {
  text-align: center !important;
  color: ui.$main-navy-full !important;
}

.total {
  text-align: center !important;
  color: ui.$main-white-full !important;
}

.leftText {
  text-align: left !important;
}
