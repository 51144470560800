@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.skillCard {
  width: 100%;
  height: 4.5rem;
  margin-bottom: 0.5rem;
  background-color: white;
  border: 1px solid ui.$contrast-grey-medium;
  border-radius: 8px;
}

.noSkill {
  @include ui.XSgrey_mediumleft;
}

.title {
  @include ui.Lnavyleft;
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
}
