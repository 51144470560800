.comment {
  width: 100%;
  margin-top: 1rem;
}

.modalIcon {
  margin-top: -1.5rem;
}

.textArea {
  height: 10rem;
}
