@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.section {
  width: 50vw;
  padding: 1.5rem 1rem 2rem 1rem;
}

.left {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: ui.$surface-body-defaut;
  z-index: 2;
  overflow-y: auto;
}
