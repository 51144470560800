@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.summary {
  margin-top: 1rem;
}

.activeBadge {
  background-color: ui.$main-navy-full;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  max-width: 100%;

  &:last-child {
    margin-right: 0;
  }
}

.input {
  border-width: 0;
}
