@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.containerParsedDocumentLabel {
  @extend .container;
}

.parsedDocumentLabelTitle {
  margin-top: 0.75rem;
}

.subtitle {
  @include ui.Mnavyleft;
}

.diplomaContainer {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.comboBoxContainer {
  display: flex;
  gap: 1rem;
  > div {
    flex-grow: 1;
  }
}

.removeItem {
  align-self: flex-start;
}
.diplomaTitle {
  width: 100%;
}

.mainDiploma {
  display: flex;
  flex-direction: column;
}
.mainDiplomaCheckBox {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.dateContainer {
  display: flex;
  column-gap: 1rem;
}

.separator {
  @extend %separator;
}
.addButton {
  width: max-content;
}
