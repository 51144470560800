@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.gearButton {
  color: ui.$main-white-medium !important;
  padding: 0.5rem !important;
  border-width: 0rem !important;
  height: 3rem !important;
  width: 3rem !important;
  min-width: 0rem !important;
}
