@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  @include ui.Lnavyleft;
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
}

.agreementContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 72%;
  margin-top: 0.75rem;
}

.mobilityToggleContainer {
  width: 72%;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  width: 88.5%;
}

.nameInputContainer {
  @include ui.Snavyleft;
  margin-right: 1rem;
  width: 43%;
  max-width: 21rem;
}

.separator {
  height: 1px;
  margin-top: 2.5rem;
  margin-bottom: 0.875rem;
  background-color: ui.$contrast-grey-medium;
}

.question {
  min-height: 10rem;
}

.questionSpace {
  margin-bottom: 2rem;
  max-width: 88%;
}

.button {
  width: 12.75rem;
  margin-top: 2.5rem;
}

.errorMessage {
  @include ui.XSerrorleft;
  margin-top: 0.25rem;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 2rem;
  width: 89.5%;
}

.cardsContainer {
  width: 88%;
}

.icon {
  width: 1rem;
  height: 1rem;
}

.experience {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include ui.Snavyleft;
  margin-bottom: 1rem;
}

.companyListButton {
  @include ui.XSblueleft;
  padding-left: 0.5rem;
}

.companyListButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 1rem;
  cursor: pointer;
}

.companyName {
  @include ui.Snavyleft;
  margin-left: 1.5rem;
  margin-top: 1rem;
}

.cityName {
  margin-bottom: 1rem;
}
