@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  margin-top: 2rem;
}

.statsContainer {
  display: flex;
  flex-direction: row;
}

.cdiGoalsUploadContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  right: 1.25rem;
  bottom: 1.25rem;
}
.cdiGoalsUploadInput {
  display: none;
}
.uploadCloudOutline {
  width: 2rem;
  height: 2rem;
  position: relative;
  cursor: pointer;
}

.noGoal {
  @include ui.XLblueleft;
}

.noOpenR1 {
  @include ui.XLnavyleft;
  margin-top: 2.5rem;
}
