@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.listContainer {
  margin-top: 1.5rem;
}

.criteriaCard {
  border: none;
  padding-left: 1rem;
  padding-right: 0rem;
}

.separator {
  @extend %separator;
}

.topLanguageButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 8rem;
  width: 11rem;

  &:not(.disabled) {
    &:hover {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: -1px;
      margin-bottom: -1px;
      height: calc(8rem + 2px);
    }
  }
}

.topLanguageButtonLoader {
  height: 8rem;
  width: 11rem;
  border: 1px solid ui.$contrast-grey-strong;
  border-radius: 4px;
}

.topLanguageFlagContainer {
  margin-right: 0;

  svg {
    height: 4rem;
    width: 4rem;
  }
}

.topLanguagesGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 2rem;
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
}

.selected {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: -1px;
  margin-bottom: -1px;
  height: calc(8rem + 2px);
}

.defaultIcon {
  height: 3rem;
  width: 3rem;
}

.selectedNumberInfo {
  position: absolute;
  @include ui.Snavyright;
  top: 9.5rem;
  right: 1.5rem;
}
