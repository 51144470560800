.container {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 1.5rem; // height of svg
  float: right;
  margin-bottom: 3px;
}

.container {
  div + div {
    margin-left: 0.2rem;
  }
}

.largeContainer {
  height: 2.5rem; // height of svg
  div + div {
    margin-left: 0.297rem;
  }
}

.largeIcon {
  height: 2.5rem;
  width: 0.703rem;
}
