@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-block: 1rem;
}

.titleContainer {
  @include ui.Titre_XS;
  color: ui.$text-heading-idle;
}

.qualifications {
  @include ui.Paragraph;
}

.experiencesContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-right: 0.25rem;
}

.illustration {
  @include ui.Paragraph;
  color: ui.$text-body-idle;
  display: flex;
  gap: 1rem;
  align-self: flex-start;
  align-items: center;
}

.groupLabel {
  @include ui.Titre_XS;
  color: ui.$text-heading-idle;
  background-color: ui.$surface-body-defaut;
  height: 2.5rem;
  text-transform: lowercase;
  padding-inline: 1rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
}
