@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ui.$main-white-full;
  padding: 0.625rem 0.5rem 0.625rem 1rem;
  border: 1px solid ui.$contrast-grey-medium;
  border-radius: 4px;
}

.leftSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.rightSide {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 1rem;
  overflow: hidden;
}

.trashIcon {
  padding: 0.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  max-width: 2.5rem;
  margin-left: 0.475rem;
}

.title {
  @include ui.Snavyleft;
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.subtitle {
  @include ui.XSgrey_mediumleft;
  margin-top: 0.438rem;
}
