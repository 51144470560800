@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  flex: 1 1 0px;
  margin-right: 1rem;
  background-color: ui.$main-blue-lighter;
  border-radius: 8px;
}
.title {
  @include ui.XSnavyleft;
  height: 32px;
  margin: 1rem 1rem 0 1rem;
}

.value {
  @include ui.Mblueleft;
  margin: 1rem;
}
