@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  row-gap: 0.5rem;
}

.subtitle {
  @include ui.Mnavyleft;
}

.flux {
  transform: rotate(90deg);
}

.hardSkillContainer {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.hardSkillTitle {
  width: 100%;
}

.separator {
  @extend %separator;
  margin-top: 0.5rem;
}

.comboBoxContainer {
  display: flex;
  flex-grow: 1;
  gap: 1rem;
  > div {
    flex-grow: 1;
  }
}
