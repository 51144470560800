@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  width: 100%;
  margin-top: 1.5rem;
}

.title {
  @include ui.Lnavyleft;
  margin-bottom: 1.5rem;
}
