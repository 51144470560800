@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.subtitle {
  @include ui.Snavyleft;
  margin-bottom: 1rem;
}

.container {
  > div {
    width: 100%;
    height: 100% !important;
  }
}

.pendingR1 {
  background-color: ui.$system-info;
}

.pendingR2 {
  background-color: ui.$system-sucess;
}

.refused {
  background-color: ui.$system-danger;
}

.acceptedR1 {
  background-color: ui.$main-yellow-full;
  color: ui.$main_navy_full;
}

.acceptedR2 {
  background-color: ui.$main-greenish-full;
  color: ui.$main_navy_full;
}

.inPlanningR2 {
  background-color: ui.$main-yellow-medium;
  color: ui.$main_navy_full;
}

.kanban {
  height: calc(100vh - 20rem) !important;
}

.signedCDIBadge {
  background-color: ui.$main_navy_full;
}
