@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.card {
  flex-direction: column;
  align-items: stretch;
}

.header {
  padding: 1rem;
  margin: -1rem -1rem 0;
  background-color: black;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  &.navy {
    background-color: ui.$surface-feedback-dark-brand-full;
  }
  &.yellow {
    background-color: ui.$surface-feedback-alt-yellow-full;
  }
  &.greenish {
    background-color: ui.$surface-feedback-alt-greenish-full;
  }
}

.checkbox {
  position: absolute;
  top: 1rem;
  right: 1rem;
  pointer-events: none;
}

.name {
  @include ui.Paragraph;
  color: ui.$text-body-idle;
}

.qualification {
  @include ui.Legend;
  color: ui.$text-body-idle;
}

.address {
  @include ui.Legend;
  color: ui.$palette-alpha-white-60;
}

.content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 1;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: stretch;

  hr {
    border: 1px none ui.$border-feedback-disable-medium;
    border-bottom-style: solid;
  }
}

.additionalInfo {
  display: flex;
}

.status {
  @include ui.Subheader;
  color: ui.$text-body-idle;
  flex: 1 1;
}

.scoring {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @include ui.Legend;
  color: ui.$text-body-sstxt;
}

.contract {
  @include ui.Tag;
  color: ui.$text-body-idle;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.stats {
  flex: 1 1;
  display: flex;
  align-items: stretch;
}

.stat {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include ui.Subheader;
  color: ui.$text-body-idle;
  > :last-child {
    @include ui.Legend;
  }
}
