@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.container > * {
  flex: 1;
}
