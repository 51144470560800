@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.title {
  @include ui.Lnavyleft;
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
}

.dateContainer {
  display: flex;
  flex-direction: row;
}

.datePickerInputContainer {
  margin-right: 1rem;
}

.button {
  width: 16rem;
  margin-top: 2.5rem;
}

.errorMessage {
  @include ui.XSerrorleft;
  margin-top: 0.25rem;
}

.separator {
  height: 1px;
  margin-top: 3.5rem;
  margin-bottom: 0.875rem;
  background-color: ui.$contrast-grey-medium;
}

.recruiter {
  @include ui.Snavyleft;
}

.recruiterContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cgcContainer {
  width: calc(66% - 0.5rem);
  margin-right: 1rem;
}

.recruiterName {
  width: calc(33% - 0.5rem);
}

.customer {
  @include ui.Snavyleft;
}

.customerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}

.sectionContainer {
  width: 88%;
}
