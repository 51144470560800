@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.noResult {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
}

.noResultText {
  margin-top: 2rem;
}
