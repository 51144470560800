.container {
  margin-top: 1.5rem;
}

.button {
  flex: 1 1;
}

.leftIcon {
  text-align: left;
}
