@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.textLength {
  @include ui.XSgrey_mediumleft;
}

.textAreaWidth {
  width: 100%;
  height: 10rem;
}

.container {
  width: 88%;
  margin-bottom: 2rem;
}

.title {
  @include ui.Lnavyleft;
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
}

.button {
  width: 10.5rem;
}

.errorMessage {
  @include ui.XSerrorleft;
  margin-top: 0.25rem;
}
