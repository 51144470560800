@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 2.5rem;
}

.label {
  @include ui.Snavyleft;
  align-self: center;
}

.question {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.answerText {
  @include ui.Snavyleft;
}
