@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 2.5rem;
}

.label {
  @include ui.Snavyleft;
  align-self: center;
}

.question {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.button {
  min-width: 5rem;
  border-width: 1px;
  margin-right: 0.5rem;
}

.unselectedButton {
  min-width: 5rem;
  margin-right: 0.5rem;
  border-width: 1px;
  border-color: ui.$contrast-grey-strong;
  background-color: ui.$main-white-full;

  &:hover {
    border-color: ui.$main-blue-full;
  }
}
