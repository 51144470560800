@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.units {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
}

.unitTitle {
  @include ui.Snavyleft;
  margin-bottom: 0.5rem;
}

.unitBadge {
  color: ui.$main-white-full;
  background-color: ui.$main_navy_full;
  margin-right: 0.5rem;
}

.modify {
  @include ui.XSblueleft;
  cursor: pointer;
}

.container {
  margin-top: 1rem;
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  width: 100%;
  height: 100%;
  grid-gap: 1.5rem;
}

.noResult {
  position: relative;
}

.noResultText {
  @include ui.XXLnavyleft;
  position: absolute;
  top: 17rem;
  left: 50rem;
  width: 29rem;
}

.noResultImage {
  position: absolute;
  top: 1rem;
  width: 55rem;
}
