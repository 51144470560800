@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.pieChartLabel {
  @include ui.Swhitefullcenter;
}

.chartContainer {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  ul {
    margin-left: 1rem;
  }
  li {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
  }
}

.chartTitle {
  @include ui.Lnavyleft;
  margin-bottom: 2rem;
}

.legendLabel {
  @include ui.Snavyleft;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.space {
  margin-right: 1rem;
}

.legendContainer {
  display: flex;
  flex-direction: column;
}

.listDisplay {
  @include ui.XSblueleft;
  cursor: pointer;
}
