@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-block: 1rem;
}

.title {
  @include ui.Titre_XS;
  color: ui.$palette-navy-500;
}

.subTitle {
  @include ui.Body;
}

.searchBar {
  width: 50%;
}

.cross {
  margin-right: 1rem;
  cursor: pointer;
}

.plus {
  cursor: pointer;
}

.badge {
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
  background-color: transparent;
  border: solid 2px ui.$main-blue-400;
  color: ui.$main-blue-400;
  @include ui.Body;
}

.deleteIcon {
  height: 0.9rem;
  cursor: pointer;
}

.suggestionTitle {
  @include ui.Body;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
}

.cardTitle {
  text-wrap: wrap;
}
