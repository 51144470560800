@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  height: 4.563rem;
  background-color: ui.$main-navy-full;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  flex-shrink: 0;
}

.subContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 4.563rem;

  > div > div > div {
    height: 4.563rem;
  }
}

.appLogo {
  height: 1.5rem;
  margin-bottom: 0.1rem;
  object-fit: contain;

  @media (max-width: 60em) {
    display: none;
  }
}

.separator {
  width: 0.063rem;
  height: 1.688rem;
  background-color: ui.$contrast-grey-medium;
  margin: 0 1rem;

  @media (max-width: 60em) {
    display: none;
  }
}

.title {
  @include ui.XLwhiteleft;

  @media (max-width: 35em) {
    display: none;
  }
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  path {
    fill: ui.$main-white-medium;
  }
}

.selectedIcon {
  path {
    fill: white;
  }
}

.bottomBar {
  background-color: white;
  margin-top: 0.75rem;
}

.segmentedMenuText {
  @include ui.Mwhitelightleft;
  color: white !important;
  font-size: 18px !important;
  letter-spacing: 0 !important;
}

.bottomBar {
  background-color: white !important;
}
