@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.subtitle {
  @include ui.Mnavyleft;
}

.parsedDocumentLabelTitle {
  margin: 0.75rem 0 0.25rem 0;
}

.languageItem {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.languageContainer {
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
}

.languageLevelContainer {
  flex-grow: 1;
}

.dropDownContainer {
  display: flex;
  flex-grow: 1;
  gap: 1rem;
  > div {
    flex-grow: 1;
  }
}

.comboBoxContainer {
  flex-grow: 1;
}

.separator {
  @extend %separator;
}

.addButton {
  width: max-content;
}

.languageLevel {
  background-color: white;
}

.addButton {
  width: max-content;
}

.removeItem {
  align-self: flex-start;
}
