@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';
@use 'src/variables.scss';

.container {
  background-color: ui.$main-light-beige-500;
  border-radius: 0.5rem;
  border: var(--border-width, variables.$default-border-width) solid transparent;
  padding: calc(1rem - var(--border-width, variables.$default-border-width));
  padding: 1rem;
  min-height: 5.75rem;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  &.beige,
  &.white,
  &.greenish {
    &:focus-visible,
    &.checked {
      background-color: ui.$main-blue-100;
      border-color: ui.$main-blue-500;
    }

    &:hover {
      background-color: ui.$contrast-grey-100;
    }

    &:active {
      border-color: ui.$main-blue-500;
    }
  }

  &.beige {
    background-color: ui.$main-light-beige-500;

    &:active {
      background-color: ui.$main-navy-200;
    }
  }

  &.white {
    background-color: ui.$main-white-500;

    &:active {
      background-color: ui.$main-blue-200;
    }
  }

  &.greenish {
    background-color: ui.$main-greenish-100;

    &:active {
      background-color: ui.$contrast-grey-200;
    }
  }
}

.itemContainer {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  gap: 1rem;
  cursor: pointer;
  &:where(:has(:focus-visible), :hover) .description {
    color: ui.$main-blue-500;
  }
}

.title {
  @include ui.Subheader;
  color: ui.$palette-navy-500;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 3rem;
}

.description {
  @include ui.Body;
  color: ui.$contrast-grey-700;
  flex: 1;
  user-select: none;
}
