@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  min-width: 17.375rem;
  padding-left: 0.5rem;
  padding-top: 1.5rem;
  padding-right: 1rem;
  overflow-y: auto;
}

.goBack {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.goBackButtonText {
  width: 9rem;
  @include ui.Sblueleft;
}

.goBackArrowButton {
  width: 3.5rem;
  height: 3.5rem;
  padding: 0.5rem;
  border-width: 0rem;
  color: ui.$main-white-full;
}

.goBackArrow {
  min-width: 3rem;
  min-height: 3rem;
}

.sideBarTitle {
  @include ui.Lnavyleft;
  margin-top: 1.5rem;
}

.sideBarElementsContainer {
  padding-left: 0.5rem;
}

.checkBoxContainer {
  margin-bottom: 1rem;
  cursor: pointer;
}

.resetButton {
  @include ui.Sblueleft;
  margin-top: 0.25rem;
  cursor: pointer;
}

.disabledResetButton {
  @include ui.Sbluelightleft;
  cursor: default;
}

.criteriaBadge {
  max-width: 15.125rem;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.badgesContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  max-width: 15.375rem;
  gap: 0.5rem;
}

.typologyLabel {
  margin-left: 0.5rem;
}
