@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.strengthsContainer {
  margin-top: 1rem;
}

.separator {
  height: 1px;
  background-color: ui.$contrast-grey-light;
}

.container {
  width: 88%;
  margin-bottom: 2rem;
}

.strengthInputError {
  border-color: ui.$system-danger !important;
}

.errorMessage {
  color: ui.$system-danger;
  margin-top: 0.5rem;
  font-size: 0.75rem;
}
