.voltageButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 8rem;
  width: 17rem;

  &:not(.disabled) {
    &:hover {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: -1px;
      margin-bottom: -1px;
      height: calc(8rem + 2px);
    }
  }
}

.voltageIconContainer {
  margin-right: 0;

  svg {
    height: 4rem;
    width: 4rem;
  }
}

.voltageSelection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 2rem;
  grid-column-gap: 1rem;
}

.selected {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: -1px;
  margin-bottom: -1px;
  height: calc(8rem + 2px);
}

.dropDowns {
  margin-top: 1.125rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dropDown {
  width: 17rem;
}
