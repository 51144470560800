@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.subtitle {
  @include ui.Mnavyleft;
}

.skillContainer {
  display: flex;
  justify-content: space-between;

  flex-direction: column;
  gap: 1rem;
}

.flux {
  transform: rotate(90deg);
}
.titleContainer {
  display: flex;
  column-gap: 1rem;
}

.comboBoxContainer {
  width: 100%;
}

.separator {
  @extend %separator;
}
