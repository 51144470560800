@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-block: 1rem;
}

.title {
  @include ui.Titre_XS;
  color: ui.$palette-navy-500;
}

.typeCodeLabel {
  @include ui.Subheader;
  margin-bottom: 0.5rem;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
}

.cardTitle {
  text-wrap: wrap;
}

.noInfo {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  @include ui.Titre_S;
  color: ui.$text-heading-idle;
}
