@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.cacesCategoryContainer {
  border-top: 1px solid ui.$contrast-grey-medium;
}

.rightSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include ui.XSnavyleft;
  margin-right: 0.5rem;
}

.cacesItem {
  @include ui.Sblueleft;
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ui.$main-white-full;
  border: none;
  padding-left: 4.5rem;
  padding-right: 0rem;
  border-top: 1px solid ui.$contrast-grey-medium;

  &:hover {
    cursor: pointer;
    background-color: ui.$contrast-grey-lighter;
  }
}

.selectedCacesItem {
  @include ui.Snavyleft;
  background-color: ui.$contrast-grey-light;
}

.checkIcon {
  margin-left: auto;
  height: 1rem;
  width: 1rem;
  margin-right: 1.25rem;
}

.label {
  max-width: 27.5rem;
}
