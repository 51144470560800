@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
}

.selectedNumberInfo {
  position: absolute;
  @include ui.Snavyright;
  top: 9.5rem;
  right: 1.5rem;
}
