@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.video {
  width: 100%;
  background-color: ui.$contrast-grey-lighter;
  height: 39rem;
}
.videoContainer {
  display: flex;
  justify-content: center;
}
.addByPhotoButton {
  height: 2.5rem;
}
