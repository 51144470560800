@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.optional {
  background-color: ui.$main-blue-full;
}

.important {
  background-color: ui.$main-yellow-full;
}

.mandatory {
  background-color: ui.$system-danger;
}
