@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.neutral {
  background-color: ui.$main-blue-full;
}

.positive {
  background-color: ui.$system-sucess;
}

.negative {
  background-color: ui.$system-warning;
}

.badge {
  min-width: 6.5rem;
}
