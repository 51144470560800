@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.topBar {
  margin-inline: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 6rem;
  padding-block: 1rem;
  gap: 0.5rem;
  flex-wrap: wrap;
  flex-shrink: 0;
}

.content {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex: 1 0 auto;
}

.goBack {
  align-self: stretch;
  display: flex;
  align-items: center;
  padding-right: 1rem;
  border-right: 1px solid ui.$border-feedback-disable-medium;
}

.title {
  @include ui.Titre_S;
  color: ui.$text-heading-idle;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.children {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}

.commentsContainer {
  width: 100%;
}

.commentsTitle {
  @include ui.Legend;
  color: ui.$palette-grey-400;
}

.comments {
  @include ui.Paragraph;
  color: ui.$text-body-idle;
}
