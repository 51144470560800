@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.question {
  min-height: 10rem;
}

.button {
  width: 18.5rem;
  margin-top: 2.5rem;
}

.questionSpace {
  margin-bottom: 2rem;
  max-width: 88%;
}

.title {
  @include ui.Lnavyleft;
  margin-top: 0.625rem;
  margin-bottom: 1.5rem;
}

.cardsContainer {
  width: 88%;
}

.autosuggestContainer {
  margin-bottom: 1.5rem;
  margin-top: -1.5rem;
}
