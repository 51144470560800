@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.mainQualificationStyle {
  width: 50%;
}

.badges {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.qualificationCountBadge {
  background-color: ui.$main-yellow-full;
  margin-right: 0.5rem;
}

.jobCard {
  width: 100%;
  height: 4.625rem;
}
