@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  padding: 1.5rem 2rem;
}

.section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 3rem;
  margin-bottom: 1.75rem;
}

.title {
  @include ui.Lnavyleft;
}

.segmentedControl {
  width: 27rem;
  min-height: 2.5rem;
  padding-top: 1rem;
  :last-child {
    min-height: 2.25rem;
  }
}

.calendarIcon {
  margin-right: 0.5rem;
}

.segmentedLabels {
  text-align: left;
  font-size: 16px;
  line-height: 1.25;
}

.dropDownContainer {
  margin-top: -1.5rem;
  width: 14.75rem;
  margin-right: 1rem;
  height: 1rem;
}

.dropDown {
  background-color: ui.$main-white-full;
  @include ui.Sblueleft;
  width: 14.75rem;
  height: 2.5rem;
}

.menuBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filterContainers {
  display: flex;
  flex-direction: row;
}

.content {
  height: 100%;
}
