@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.loadingPage {
  width: 37rem;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  @include ui.XLnavyleft;
  margin: 0 1.5rem 1.5rem 1.5rem;
}

.binocular {
  width: 17.125rem;
  height: 10.125rem;
  margin-bottom: 2.5rem;
}

.tickCircleGreen {
  min-width: 1.5rem;
  min-height: 1.5rem;
}

.documentStatusContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1.125rem;
  justify-self: center;
}

.documentStatusItem {
  margin-left: 4.5rem;
  display: flex;
  gap: 0.625rem;
}

.loaderContainer {
  width: 1.5rem;
  height: 0.75rem;
  border-radius: 0.375rem;
  background-color: ui.$main-blue-full;
  display: flex;
  justify-content: center;
  align-items: center;
}

.documentStatusItemTitle {
  @include ui.Mnavyleft;
}
