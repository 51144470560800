@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.grid {
  width: 100%;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
}

.link {
  @include ui.Sblueleft;
  margin-bottom: 1.5rem;
  text-decoration: none;
}
