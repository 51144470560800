@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
  padding-left: 2rem;
  overflow-y: auto;
  padding-top: 1.5rem;
  width: 100%;
}

.routeNumber {
  margin-top: 0.7rem;
  width: 1.5rem;
  @include ui.Mnavyleft;
}

.formContainer {
  flex: 1;
  flex-direction: column;
  display: flex;
  max-width: 61.5rem;
}

.titleContainer {
  padding: 0 0 1.5rem 0;

  p {
    @include ui.XLnavyleft;
  }
}

.content {
  flex: 1;
  flex-direction: column;
  display: flex;
  padding-bottom: 5rem;
}

.separator {
  height: 1px;
  background-color: ui.$contrast-grey-medium;
}
