@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  padding: 0 2rem 0 2rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
}

.illustration {
  @include ui.Paragraph;
  color: ui.$text-body-idle;
  display: flex;
  gap: 1rem;
  align-self: flex-start;
  align-items: center;
}

.tabsContainer {
  flex: 0;
}

.segmentedMenuSelectedText {
  @include ui.Lnavyleft;
}

.tabContainer {
  flex: 1 1;
  flex-direction: row;
  display: flex;
  min-height: 0;
}

.sideBarContainer {
  padding-top: 1.5rem;
  max-width: 16rem;
}

.candidateName {
  @include ui.Snavyleft;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.candidateContract {
  @include ui.XSgrey_mediumleft;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-top: 0.25rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ui.$contrast-grey-medium;
}

.loader {
  margin-top: 10rem;
  margin-left: 10rem;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
  margin-left: 2rem;
}

.errorYellowLadder {
  margin-bottom: 1rem;

  svg {
    width: 13.188rem;
    height: 16rem;
    object-fit: contain;
  }
}

.errorTitle {
  @include ui.Lnavyleft;
  margin-bottom: 0.25rem;
}

.errorDescription {
  @include ui.Mgreenishleft;
}
