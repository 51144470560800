@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.errorTitle {
  @include ui.XLnavyleft;
  margin-bottom: 0.25rem;
}

.errorContent {
  @include ui.Snavyleft;
}

.illusEscalier {
  margin: 0 1rem 0 0;

  svg {
    object-fit: contain;
    width: 5rem;
    height: 6rem;
  }
}
