@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.modalIcon {
  margin-top: -1.5rem;
}

.inputContainer {
  margin-bottom: 1rem;
}

.datesContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.datesGap {
  margin-right: 1rem;
}

.errorMessage {
  @include ui.XSerrorleft;
  margin-top: -0.75rem;
  margin-bottom: 1rem;
}
