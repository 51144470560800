@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.qualificationContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
}

.qualificationComboBox {
  display: flex;
  width: 100%;
  gap: 1rem;
  > div {
    flex-grow: 1;
  }
}

.comboBox {
  flex-grow: 1;
}

.comboBoxContainer {
  flex-grow: 1;
}

.removeItem {
  align-self: flex-start;
}

.toggleSwitchContainer {
  display: flex;
  column-gap: 1rem;
}

.mainQualification {
  display: flex;
  flex-direction: column;
}
.mainQualificationCheckBox {
  display: flex;
  height: 3.5rem;
  align-items: center;
}

.parsedDocumentLabelTitle {
  margin-bottom: 0.25rem;
}
.parsedDocumentLabelContainer {
  margin: 0 0 -0.5rem 0;
}

.qualificationTitle {
  width: 100%;
}

.separator {
  @extend %separator;
}
