@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';

.statsSummary {
  max-width: 29.625rem;
  min-width: 21rem;
  width: 33%;
  height: 8rem;
  padding: 1rem;
  background-color: ui.$main-blue-lighter;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  margin-right: 1.5rem;
  position: relative;
}
.summaryLabel {
  @include ui.Mnavyleft;
}

.summaryNumber {
  @include ui.XXXLblueleft;
}
