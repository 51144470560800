@use '~@randstad-lean-mobile-factory/react-components-ui-shared/dist/ui.scss';
.selectAllCheckbox {
  margin-bottom: 1rem;
}

.checkbox {
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.jobSearch {
  margin-bottom: 1rem;
}

.icon {
  margin-top: -1.5rem;
}

.jobName {
  @include ui.Snavyleft;
  margin-bottom: 1rem;
}

.companyAndContract {
  margin-bottom: 1rem;
  @include ui.Snavyleft;
}

.button {
  -webkit-appearance: none;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  outline: none;
  margin-top: 0px;
  border: 2px solid ui.$contrast-grey-strong;
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:before {
  content: '';
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}

.button:checked:before {
  background: ui.$main-blue-full;
}

.button:disabled {
  border: 2px solid ui.$contrast-grey-medium;
}

.button:checked {
  border-color: ui.$main-blue-full;
}

.mainQualificationTitle {
  @include ui.XSgrey_mediumcenter;
  width: 4.5rem;
}
